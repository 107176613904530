type PhoneNumberBuilderConfig = {
  countryCodeValue: string;
  phoneNumber: string;
};

const phoneNumberBuilder = ({ countryCodeValue, phoneNumber }: PhoneNumberBuilderConfig) => {
  if (!countryCodeValue) throw new Error('no_country_code');
  if (countryCodeValue.charAt(0) !== '+') throw new Error('invalid_country_code');
  if (!phoneNumber) throw new Error('no_phone_number');
  if (typeof phoneNumber !== 'string') throw new Error('phone_number_type_error');
  const trimmedPhoneNumber: string = phoneNumber.charAt(0) === '0' ? phoneNumber.substr(1) : phoneNumber;
  const finalPhoneNumber: string = `${countryCodeValue}${trimmedPhoneNumber}`;
  return finalPhoneNumber;
};

export default phoneNumberBuilder;
