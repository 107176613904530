interface AttributeNameMap {
  [key: string]: string;
}

const attributeNameMap: AttributeNameMap = {
  username: 'username',
  preferredUsername: 'preferred_username',
  password: 'password',
  name: 'name',
  phoneNumber: 'phone_number',
  city: 'custom:city',
  birthday: 'birthdate',
  email: 'email',
  showAsCollector: 'custom:show_as_collector',
  phoneNumberVerified: 'phone_number_verified',
};

export default attributeNameMap;
