import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosWithAuth } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type GetMe = () => Promise<UserType>;

const getMe: GetMe = async () => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/me`;
    const getUserResp: AxiosResponse<UserType> = await axiosWithAuth.request({
      method: 'GET',
      url: reqPath,
    });
    const status = getUserResp.status;
    if (status !== HttpStatus.OK) throw new Error('incorrect_status_code');
    return getUserResp.data;
  } catch (err) {
    throw err;
  }
};

export default getMe;
