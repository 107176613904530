import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';

import { axiosErrorHandler } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type ResendConfirmationCodeConfig = {
  username: string;
};

type ResendConfirmationCode = (resendConfirmationCodeConfig: ResendConfirmationCodeConfig) => Promise<any>;

const resendConfirmationCode: ResendConfirmationCode = async (
  resendConfirmationCodeConfig: ResendConfirmationCodeConfig,
) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/resend-code`;
    const resendConfirmationCodeResp: AxiosResponse<any> = await axios.request({
      method: 'POST',
      url: reqPath,
      data: {
        username: resendConfirmationCodeConfig.username,
      },
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = resendConfirmationCodeResp.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return resendConfirmationCodeResp.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default resendConfirmationCode;
