import jwtDecode from 'jwt-decode';
import { refreshTokens } from '../api/user';
import { updateLocalTokens } from '../utils';

interface Claims {
  [key: string]: string;
}

type DecodeVerifyJWT = () => Promise<Claims>;

const decodeVerifyJWT: DecodeVerifyJWT = async () => {
  try {
    let accessToken = localStorage.getItem('access_token');
    const exp = Number(localStorage.getItem('token_exp'));
    if (accessToken) {
      const date = new Date();
      const currentTs = Math.floor(date.valueOf() / 1000);
      // Token is expired
      if (currentTs > exp) {
        const refreshResp = await refreshTokens();
        const tokens = updateLocalTokens(refreshResp.AuthenticationResult);
        accessToken = tokens.accessToken;
        const claims: Claims = tokens.claims;
        return claims;
      }
      const claims: Claims = jwtDecode(accessToken || '');
      return claims;
    }
    throw new Error('Token not available');
  } catch (err) {
    throw err;
  }
};

export default decodeVerifyJWT;
