import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosErrorHandler } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type ConfirmRegistrationConfig = {
  code: string;
  attrName: string;
  username: string;
};

type ConfirmRegistration = (config: ConfirmRegistrationConfig) => Promise<string>;

const confirmRegistration: ConfirmRegistration = async ({ username, code, attrName }: ConfirmRegistrationConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/confirm-signup`;
    const confirmRegistrationObject: AxiosResponse<string> = await axios.request({
      method: 'POST',
      url: reqPath,
      data: {
        code,
        attrName,
        username,
      },
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = confirmRegistrationObject.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return confirmRegistrationObject.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default confirmRegistration;
