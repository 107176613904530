import { decodeVerifyJWT } from '../auth';
import Router from 'next/router';

const refreshExpiredTokens = async () => {
  try {
    await decodeVerifyJWT();
  } catch (err) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_exp');
    Router.push('/login');
  }
};

export default refreshExpiredTokens;
