import axios from 'axios';
import HttpStatus from 'http-status-codes';
import Router from 'next/router';
import { refreshTokens } from '../api/user';
import getConfig from 'next/config';
import { updateLocalTokens } from './';

const { publicRuntimeConfig } = getConfig();

const axiosWithAuth = axios.create({
  headers: {
    'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
  },
});

let isRefreshing = false;
let requests: any[] = [];

axiosWithAuth.interceptors.request.use(
  //@ts-ignore
  config => {
    let accessToken = localStorage.getItem('access_token');
    const exp = Number(localStorage.getItem('token_exp'));
    if (accessToken) {
      const date = new Date();
      const currentTs = Math.floor(date.valueOf() / 1000);
      // Token is expired
      if (currentTs > exp) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshTokens()
            .then(refreshResp => {
              accessToken = updateLocalTokens(refreshResp.AuthenticationResult).accessToken;
              isRefreshing = false;
              return accessToken;
            })
            .then(accessToken => {
              requests.forEach(cb => {
                cb(accessToken);
              });
              requests = [];
            })
            .catch(err => {
              if (err.response) {
                if (err.response.status == HttpStatus.UNAUTHORIZED) {
                  localStorage.removeItem('access_token');
                  localStorage.removeItem('id_token');
                  localStorage.removeItem('refresh_token');
                  localStorage.removeItem('token_exp');
                  requests = [];
                  Router.push('/login');
                }
              }
              throw err;
            });
        }
        const retryOriginalRequest = new Promise(resolve => {
          requests.push((token: string) => {
            config.headers['Authorization'] = 'Bearer ' + token;
            resolve(config);
          });
        });
        return retryOriginalRequest;
      }
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  },
);

export default axiosWithAuth;
