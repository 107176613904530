import { CountryCodeNew, CountryCodes } from '../types';

export const drawerWidthMedium = 56;
export const drawerWidthLarge = 240;

// To use with the react-select date picker
export const monthOptions = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const countries: CountryCodes[] = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61' },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1' },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49' },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33' },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81' },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1' },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

// Country codes with flag emoji code points to ensure JavaScript handles them correctly
export const countryCodes: CountryCodeNew[] = [
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1eb)} Afghanistan (+93)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1eb)} AF`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1eb),
    country: 'Afghanistan',
    countryAbbr: 'AF',
    countryAbbr3: 'AFG',
    value: '+93',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1f1)} Albania (+355)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1f1)} AL`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1f1),
    country: 'Albania',
    countryAbbr: 'AL',
    countryAbbr3: 'ALB',
    value: '+355',
  },
  {
    label: `${String.fromCodePoint(0x1f1e9, 0x1f1ff)} Algeria (+213)`,
    shortLabel: `${String.fromCodePoint(0x1f1e9, 0x1f1ff)} DZ`,
    flagCodePoint: String.fromCodePoint(0x1f1e9, 0x1f1ff),
    country: 'Algeria',
    countryAbbr: 'DZ',
    countryAbbr3: 'DZA',
    value: '+213',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1e9)} Andorra (+376)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1e9)} AD`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1e9),
    country: 'Andorra',
    countryAbbr: 'AD',
    countryAbbr3: 'AND',
    value: '+376',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1f4)} Angola (+244)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1f4)} AO`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1f4),
    country: 'Angola',
    countryAbbr: 'AO',
    countryAbbr3: 'AGO',
    value: '+244',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1ec)} Antigua and Barbuda (+1268)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1ec)} AG`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1ec),
    country: 'Antigua and Barbuda',
    countryAbbr: 'AG',
    countryAbbr3: 'ATG',
    value: '+1268',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1f7)} Argentina (+54)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1f7)} AR`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1f7),
    country: 'Argentine',
    countryAbbr: 'AR',
    countryAbbr3: 'ARG',
    value: '+54',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1f2)} Armenia (+374)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1f2)} AM`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1f2),
    country: 'Armenia',
    countryAbbr: 'AM',
    countryAbbr3: 'ARM',
    value: '+374',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1fa)} Australia (+61)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1fa)} AU`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1fa),
    country: 'Australia',
    countryAbbr: 'AU',
    countryAbbr3: 'AUS',
    value: '+61',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1f9)} Austria (+43)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1f9)} AT`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1f9),
    country: 'Austria',
    countryAbbr: 'AT',
    countryAbbr3: 'AUT',
    value: '+43',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1ff)} Azerbaijan (+994)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1ff)} AZ`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1ff),
    country: 'Azerbaijan',
    countryAbbr: 'AZ',
    countryAbbr3: 'AZE',
    value: '+994',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1f8)} Bahamas (+1242)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1f8)} BS`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1f8),
    country: 'Bahamas',
    countryAbbr: 'BS',
    countryAbbr3: 'BHS',
    value: '+1242',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1ed)} Bahrain (+973)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1ed)} BH`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1ed),
    country: 'Bahrain',
    countryAbbr: 'BH',
    countryAbbr3: 'BHR',
    value: '+973',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1e9)} Bangladesh (+880)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1e9)} BD`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1e9),
    country: 'Bangladesh',
    countryAbbr: 'BD',
    countryAbbr3: 'BGD',
    value: '+880',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1e7)} Barbados (+1246)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1e7)} BB`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1e7),
    country: 'Barbados',
    countryAbbr: 'BB',
    countryAbbr3: 'BRB',
    value: '+1246',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1fe)} Belarus (+375)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1fe)} BY`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1fe),
    country: 'Belarus',
    countryAbbr: 'BY',
    countryAbbr3: 'BLR',
    value: '+375',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1ea)} Belgium (+32)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1ea)} BE`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1ea),
    country: 'Belgium',
    countryAbbr: 'BE',
    countryAbbr3: 'BEL',
    value: '+32',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1ff)} Belize (+501)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1ff)} BZ`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1ff),
    country: 'Belize',
    countryAbbr: 'BZ',
    countryAbbr3: 'BLZ',
    value: '+501',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1ef)} Benin (+229)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1ef)} BJ`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1ef),
    country: 'Benin',
    countryAbbr: 'BJ',
    countryAbbr3: 'BEN',
    value: '+229',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1f9)} Bhutan (+975)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1f9)} BT`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1f9),
    country: 'Bhutan',
    countryAbbr: 'BT',
    countryAbbr3: 'BTN',
    value: '+975',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1f4)} Bolivia (+591)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1f4)} BO`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1f4),
    country: 'Bolivia',
    countryAbbr: 'BO',
    countryAbbr3: 'BOL',
    value: '+591',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1e6)} Bosnia and Herzegovina (+387)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1e6)} BA`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1e6),
    country: 'Bosnia and Herzegovina',
    countryAbbr: 'BA',
    countryAbbr3: 'BIH',
    value: '+387',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1fc)} Botswana (+267)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1fc)} BW`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1fc),
    country: 'Botswana',
    countryAbbr: 'BW',
    countryAbbr3: 'BWA',
    value: '+267',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1f7)} Brazil (+55)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1f7)} BR`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1f7),
    country: 'Brazil',
    countryAbbr: 'BR',
    countryAbbr3: 'BRA',
    value: '+55',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1f3)} Brunei (+673)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1f3)} BN`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1f3),
    country: 'Brunei',
    countryAbbr: 'BN',
    countryAbbr3: 'BRN',
    value: '+673',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1ec)} Bulgaria (+359)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1ec)} BG`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1ec),
    country: 'Bulgaria',
    countryAbbr: 'BG',
    countryAbbr3: 'BGR',
    value: '+359',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1eb)} Burkina Faso (+226)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1eb)} BF`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1eb),
    country: 'Burkina Faso',
    countryAbbr: 'BF',
    countryAbbr3: 'BFA',
    value: '+226',
  },
  {
    label: `${String.fromCodePoint(0x1f1e7, 0x1f1ee)} Burundi (+257)`,
    shortLabel: `${String.fromCodePoint(0x1f1e7, 0x1f1ee)} BI`,
    flagCodePoint: String.fromCodePoint(0x1f1e7, 0x1f1ee),
    country: 'Burundi',
    countryAbbr: 'BI',
    countryAbbr3: 'BDI',
    value: '+257',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1ee)} Côte d\'Ivoire (+225)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1ee)} CI`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1ee),
    country: "Côte d'Ivoire",
    countryAbbr: 'CI',
    countryAbbr3: 'CIV',
    value: '+225',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1fb)} Cabo Verde (+238)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1fb)} CV`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1fb),
    country: 'Cabo Verde',
    countryAbbr: 'CV',
    countryAbbr3: 'CPV',
    value: '+238',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1ed)} Cambodia (+855)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1ed)} KH`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1ed),
    country: 'Cambodia',
    countryAbbr: 'KH',
    countryAbbr3: 'KHM',
    value: '+855',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1f2)} Cameroon (+237)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1f2)} CM`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1f2),
    country: 'Cameroon',
    countryAbbr: 'CM',
    countryAbbr3: 'CMR',
    value: '+237',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1e6)} Canada (+1)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1e6)} CA`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1e6),
    country: 'Canada',
    countryAbbr: 'CA',
    countryAbbr3: 'CAN',
    value: '+1',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1eb)} Central African Republic (+236)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1eb)} CF`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1eb),
    country: 'Central African Republic',
    countryAbbr: 'CF',
    countryAbbr3: 'CAF',
    value: '+236',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1e9)} Chad (+235)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1e9)} TD`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1e9),
    country: 'Chad',
    countryAbbr: 'TD',
    countryAbbr3: 'TCD',
    value: '+235',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1f1)} Chile (+56)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1f1)} CL`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1f1),
    country: 'Chile',
    countryAbbr: 'CL',
    countryAbbr3: 'CHL',
    value: '+56',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1f3)} China (+86)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1f3)} CN`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1f3),
    country: "People's Republic of China",
    countryAbbr: 'CN',
    countryAbbr3: 'CHN',
    value: '+86',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1f4)} Colombia (+57)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1f4)} CO`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1f4),
    country: 'Colombia',
    countryAbbr: 'CO',
    countryAbbr3: 'COL',
    value: '+57',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1f2)} Comoros (+269)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1f2)} KM`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1f2),
    country: 'Comoros',
    countryAbbr: 'KM',
    countryAbbr3: 'COM',
    value: '+269',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1ec)} Congo (Congo-Brazzaville) (+242)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1ec)} CG`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1ec),
    country: 'Congo (Congo-Brazzaville)',
    countryAbbr: 'CG',
    countryAbbr3: 'COG',
    value: '+242',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1f7)} Costa Rica (+506)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1f7)} CR`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1f7),
    country: 'Costa Rica',
    countryAbbr: 'CR',
    countryAbbr3: 'CRI',
    value: '+506',
  },
  {
    label: `${String.fromCodePoint(0x1f1ed, 0x1f1f7)} Croatia (+385)`,
    shortLabel: `${String.fromCodePoint(0x1f1ed, 0x1f1f7)} HR`,
    flagCodePoint: String.fromCodePoint(0x1f1ed, 0x1f1f7),
    country: 'Croatia',
    countryAbbr: 'HR',
    countryAbbr3: 'HRV',
    value: '+385',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1fa)} Cuba (+53)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1fa)} CU`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1fa),
    country: 'Cuba',
    countryAbbr: 'CU',
    countryAbbr3: 'CUB',
    value: '+53',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1fe)} Cyprus (+357)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1fe)} CY`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1fe),
    country: 'Cyprus',
    countryAbbr: 'CY',
    countryAbbr3: 'CYP',
    value: '+357',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1ff)} Czech Republic (+420)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1ff)} CZ`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1ff),
    country: 'Czech Republic',
    countryAbbr: 'CZ',
    countryAbbr3: 'CZE',
    value: '+420',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1e9)} Democratic Republic of the Congo (+243)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1e9)} CD`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1e9),
    country: 'Democratic Republic of the Congo',
    countryAbbr: 'CD',
    countryAbbr3: 'COD',
    value: '+243',
  },
  {
    label: `${String.fromCodePoint(0x1f1e9, 0x1f1f0)} Denmark (+45)`,
    shortLabel: `${String.fromCodePoint(0x1f1e9, 0x1f1f0)} DK`,
    flagCodePoint: String.fromCodePoint(0x1f1e9, 0x1f1f0),
    country: 'Denmark',
    countryAbbr: 'DK',
    countryAbbr3: 'DNK',
    value: '+45',
  },
  {
    label: `${String.fromCodePoint(0x1f1e9, 0x1f1ef)} Djibouti (+253)`,
    shortLabel: `${String.fromCodePoint(0x1f1e9, 0x1f1ef)} DJ`,
    flagCodePoint: String.fromCodePoint(0x1f1e9, 0x1f1ef),
    country: 'Djibouti',
    countryAbbr: 'DJ',
    countryAbbr3: 'DJI',
    value: '+253',
  },
  {
    label: `${String.fromCodePoint(0x1f1e9, 0x1f1f2)} Dominica (+767)`,
    shortLabel: `${String.fromCodePoint(0x1f1e9, 0x1f1f2)} DM`,
    flagCodePoint: String.fromCodePoint(0x1f1e9, 0x1f1f2),
    country: 'Dominica',
    countryAbbr: 'DM',
    countryAbbr3: 'DMA',
    value: '+767',
  },
  {
    label: `${String.fromCodePoint(0x1f1e9, 0x1f1f4)} Dominican Republic (+809)`,
    shortLabel: `${String.fromCodePoint(0x1f1e9, 0x1f1f4)} DO`,
    flagCodePoint: String.fromCodePoint(0x1f1e9, 0x1f1f4),
    country: 'Dominican Republic',
    countryAbbr: 'DO',
    countryAbbr3: 'DOM',
    value: '+809',
  },
  {
    label: `${String.fromCodePoint(0x1f1ea, 0x1f1e8)} Ecuador (+593)`,
    shortLabel: `${String.fromCodePoint(0x1f1ea, 0x1f1e8)} EC`,
    flagCodePoint: String.fromCodePoint(0x1f1ea, 0x1f1e8),
    country: 'Ecuador',
    countryAbbr: 'EC',
    countryAbbr3: 'ECU',
    value: '+593',
  },
  {
    label: `${String.fromCodePoint(0x1f1ea, 0x1f1ec)} Egypt (+20)`,
    shortLabel: `${String.fromCodePoint(0x1f1ea, 0x1f1ec)} EG`,
    flagCodePoint: String.fromCodePoint(0x1f1ea, 0x1f1ec),
    country: 'Egypt',
    countryAbbr: 'EG',
    countryAbbr3: 'EGY',
    value: '+20',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1fb)} El Salvador (+503)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1fb)} SV`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1fb),
    country: 'El Salvador',
    countryAbbr: 'SV',
    countryAbbr3: 'SLV',
    value: '+503',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1f6)} Equatorial Guinea (+240)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1f6)} GQ`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1f6),
    country: 'Equatorial Guinea',
    countryAbbr: 'GQ',
    countryAbbr3: 'GNQ',
    value: '+240',
  },
  {
    label: `${String.fromCodePoint(0x1f1ea, 0x1f1f7)} Eritrea (+291)`,
    shortLabel: `${String.fromCodePoint(0x1f1ea, 0x1f1f7)} ER`,
    flagCodePoint: String.fromCodePoint(0x1f1ea, 0x1f1f7),
    country: 'Eritrea',
    countryAbbr: 'ER',
    countryAbbr3: 'ERI',
    value: '+291',
  },
  {
    label: `${String.fromCodePoint(0x1f1ea, 0x1f1ea)} Estonia (+372)`,
    shortLabel: `${String.fromCodePoint(0x1f1ea, 0x1f1ea)} EE`,
    flagCodePoint: String.fromCodePoint(0x1f1ea, 0x1f1ea),
    country: 'Estonia',
    countryAbbr: 'EE',
    countryAbbr3: 'EST',
    value: '+372',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1ff)} Eswatini (+268)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1ff)} SZ`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1ff),
    country: 'Eswatini',
    countryAbbr: 'SZ',
    countryAbbr3: 'SWZ',
    value: '+268',
  },
  {
    label: `${String.fromCodePoint(0x1f1ea, 0x1f1f9)} Ethiopia (+251)`,
    shortLabel: `${String.fromCodePoint(0x1f1ea, 0x1f1f9)} ET`,
    flagCodePoint: String.fromCodePoint(0x1f1ea, 0x1f1f9),
    country: 'Ethiopia',
    countryAbbr: 'ET',
    countryAbbr3: 'ETH',
    value: '+251',
  },
  {
    label: `${String.fromCodePoint(0x1f1eb, 0x1f1ef)} Fiji (+679)`,
    shortLabel: `${String.fromCodePoint(0x1f1eb, 0x1f1ef)} FJ`,
    flagCodePoint: String.fromCodePoint(0x1f1eb, 0x1f1ef),
    country: 'Fiji',
    countryAbbr: 'FJ',
    countryAbbr3: 'FJI',
    value: '+679',
  },
  {
    label: `${String.fromCodePoint(0x1f1eb, 0x1f1ee)} Finland (+358)`,
    shortLabel: `${String.fromCodePoint(0x1f1eb, 0x1f1ee)} FI`,
    flagCodePoint: String.fromCodePoint(0x1f1eb, 0x1f1ee),
    country: 'Finland',
    countryAbbr: 'FI',
    countryAbbr3: 'FIN',
    value: '+358',
  },
  {
    label: `${String.fromCodePoint(0x1f1eb, 0x1f1f7)} France (+33)`,
    shortLabel: `${String.fromCodePoint(0x1f1eb, 0x1f1f7)} FR`,
    flagCodePoint: String.fromCodePoint(0x1f1eb, 0x1f1f7),
    country: 'France',
    countryAbbr: 'FR',
    countryAbbr3: 'FRA',
    value: '+33',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1e6)} Gabon (+241)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1e6)} GA`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1e6),
    country: 'Gabon',
    countryAbbr: 'GA',
    countryAbbr3: 'GAB',
    value: '+241',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1f2)} Gambia (+220)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1f2)} GM`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1f2),
    country: 'Gambia',
    countryAbbr: 'GM',
    countryAbbr3: 'GMB',
    value: '+220',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1ea)} Georgia (+995)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1ea)} GE`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1ea),
    country: 'Georgia',
    countryAbbr: 'GE',
    countryAbbr3: 'GEO',
    value: '+995',
  },
  {
    label: `${String.fromCodePoint(0x1f1e9, 0x1f1ea)} Germany (+49)`,
    shortLabel: `${String.fromCodePoint(0x1f1e9, 0x1f1ea)} DE`,
    flagCodePoint: String.fromCodePoint(0x1f1e9, 0x1f1ea),
    country: 'Germany',
    countryAbbr: 'DE',
    countryAbbr3: 'DEU',
    value: '+49',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1ed)} Ghana (+233)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1ed)} GH`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1ed),
    country: 'Ghana',
    countryAbbr: 'GH',
    countryAbbr3: 'GHA',
    value: '+233',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1f7)} Greece (+30)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1f7)} GR`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1f7),
    country: 'Greece',
    countryAbbr: 'GR',
    countryAbbr3: 'GRC',
    value: '+30',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1e9)} Grenada (+1473)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1e9)} GD`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1e9),
    country: 'Grenada',
    countryAbbr: 'GD',
    countryAbbr3: 'GRD',
    value: '+1473',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1fa)} Guam (+1671)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1fa)} GU`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1fa),
    country: 'Guam',
    countryAbbr: 'GU',
    countryAbbr3: 'GUA',
    value: '+1671',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1f9)} Guatemala (+502)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1f9)} GT`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1f9),
    country: 'Guatemala',
    countryAbbr: 'GT',
    countryAbbr3: 'GTM',
    value: '+502',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1f3)} Guinea (+224)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1f3)} GN`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1f3),
    country: 'Guinea',
    countryAbbr: 'GN',
    countryAbbr3: 'GIN',
    value: '+224',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1fc)} Guinea-Bissau (+245)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1fc)} GW`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1fc),
    country: 'Guinea-Bissau',
    countryAbbr: 'GW',
    countryAbbr3: 'GNB',
    value: '+245',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1fe)} Guyana (+592)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1fe)} GY`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1fe),
    country: 'Guyana',
    countryAbbr: 'GY',
    countryAbbr3: 'GUY',
    value: '+592',
  },
  {
    label: `${String.fromCodePoint(0x1f1ed, 0x1f1f9)} Haiti (+509)`,
    shortLabel: `${String.fromCodePoint(0x1f1ed, 0x1f1f9)} HT`,
    flagCodePoint: String.fromCodePoint(0x1f1ed, 0x1f1f9),
    country: 'Haiti',
    countryAbbr: 'HT',
    countryAbbr3: 'HTI',
    value: '+509',
  },
  {
    label: `${String.fromCodePoint(0x1f1ed, 0x1f1f3)} Honduras (+504)`,
    shortLabel: `${String.fromCodePoint(0x1f1ed, 0x1f1f3)} HN`,
    flagCodePoint: String.fromCodePoint(0x1f1ed, 0x1f1f3),
    country: 'Honduras',
    countryAbbr: 'HN',
    countryAbbr3: 'HND',
    value: '+504',
  },
  {
    label: `${String.fromCodePoint(0x1f1ed, 0x1f1f0)} Hong Kong (+852)`,
    shortLabel: `${String.fromCodePoint(0x1f1ed, 0x1f1f0)} HK`,
    flagCodePoint: String.fromCodePoint(0x1f1ed, 0x1f1f0),
    country: 'Hong Kong',
    countryAbbr: 'HK',
    countryAbbr3: 'HKG',
    value: '+852',
  },
  {
    label: `${String.fromCodePoint(0x1f1ed, 0x1f1fa)} Hungary (+36)`,
    shortLabel: `${String.fromCodePoint(0x1f1ed, 0x1f1fa)} HU`,
    flagCodePoint: String.fromCodePoint(0x1f1ed, 0x1f1fa),
    country: 'Hungary',
    countryAbbr: 'HU',
    countryAbbr3: 'HUN',
    value: '+36',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1f8)} Iceland (+354)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1f8)} IS`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1f8),
    country: 'Iceland',
    countryAbbr: 'IS',
    countryAbbr3: 'ISL',
    value: '+354',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1f3)} India (+91)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1f3)} IN`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1f3),
    country: 'India',
    countryAbbr: 'IN',
    countryAbbr3: 'IND',
    value: '+91',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1e9)} Indonesia (+62)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1e9)} ID`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1e9),
    country: 'Indonesia',
    countryAbbr: 'ID',
    countryAbbr3: 'IDN',
    value: '+62',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1f7)} Iran (+98)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1f7)} IR`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1f7),
    country: 'Iran',
    countryAbbr: 'IR',
    countryAbbr3: 'IRN',
    value: '+98',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1f6)} Iraq (+964)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1f6)} IQ`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1f6),
    country: 'Iraq',
    countryAbbr: 'IQ',
    countryAbbr3: 'IRQ',
    value: '+964',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1ea)} Ireland (+353)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1ea)} IE`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1ea),
    country: 'Ireland',
    countryAbbr: 'IE',
    countryAbbr3: 'IRL',
    value: '+353',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1f1)} Israel (+972)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1f1)} IL`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1f1),
    country: 'Israel',
    countryAbbr: 'IL',
    countryAbbr3: 'ISR',
    value: '+972',
  },
  {
    label: `${String.fromCodePoint(0x1f1ee, 0x1f1f9)} Italy (+39)`,
    shortLabel: `${String.fromCodePoint(0x1f1ee, 0x1f1f9)} IT`,
    flagCodePoint: String.fromCodePoint(0x1f1ee, 0x1f1f9),
    country: 'Italy',
    countryAbbr: 'IT',
    countryAbbr3: 'ITA',
    value: '+39',
  },
  {
    label: `${String.fromCodePoint(0x1f1ef, 0x1f1f2)} Jamaica (+876)`,
    shortLabel: `${String.fromCodePoint(0x1f1ef, 0x1f1f2)} JM`,
    flagCodePoint: String.fromCodePoint(0x1f1ef, 0x1f1f2),
    country: 'Jamaica',
    countryAbbr: 'JM',
    countryAbbr3: 'JAM',
    value: '+876',
  },
  {
    label: `${String.fromCodePoint(0x1f1ef, 0x1f1f5)} Japan (+81)`,
    shortLabel: `${String.fromCodePoint(0x1f1ef, 0x1f1f5)} JP`,
    flagCodePoint: String.fromCodePoint(0x1f1ef, 0x1f1f5),
    country: 'Japan',
    countryAbbr: 'JP',
    countryAbbr3: 'JPN',
    value: '+81',
  },
  {
    label: `${String.fromCodePoint(0x1f1ef, 0x1f1f4)} Jordan (+962)`,
    shortLabel: `${String.fromCodePoint(0x1f1ef, 0x1f1f4)} JO`,
    flagCodePoint: String.fromCodePoint(0x1f1ef, 0x1f1f4),
    country: 'Jordan',
    countryAbbr: 'JO',
    countryAbbr3: 'JOR',
    value: '+962',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1ff)} Kazakhstan (+7)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1ff)} KZ`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1ff),
    country: 'Kazakhstan',
    countryAbbr: 'KZ',
    countryAbbr3: 'KAZ',
    value: '+7',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1ea)} Kenya (+254)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1ea)} KE`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1ea),
    country: 'Kenya',
    countryAbbr: 'KE',
    countryAbbr3: 'KEN',
    value: '+254',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1ee)} Kiribati (+686)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1ee)} KI`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1ee),
    country: 'Kiribati',
    countryAbbr: 'KI',
    countryAbbr3: 'KIR',
    value: '+686',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1fc)} Kuwait (+965)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1fc)} KW`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1fc),
    country: 'Kuwait',
    countryAbbr: 'KW',
    countryAbbr3: 'KWT',
    value: '+965',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1ec)} Kyrgyzstan (+996)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1ec)} KG`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1ec),
    country: 'Kyrgyzstan',
    countryAbbr: 'KG',
    countryAbbr3: 'KGZ',
    value: '+996',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1e6)} Laos (+856)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1e6)} LA`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1e6),
    country: 'Laos',
    countryAbbr: 'LA',
    countryAbbr3: 'LAO',
    value: '+856',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1fb)} Latvia (+371)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1fb)} LV`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1fb),
    country: 'Latvia',
    countryAbbr: 'LV',
    countryAbbr3: 'LVA',
    value: '+371',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1e7)} Lebanon (+961)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1e7)} LB`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1e7),
    country: 'Lebanon',
    countryAbbr: 'LB',
    countryAbbr3: 'LBN',
    value: '+961',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1f8)} Lesotho (+266)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1f8)} LS`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1f8),
    country: 'Lesotho',
    countryAbbr: 'LS',
    countryAbbr3: 'LSO',
    value: '+266',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1f7)} Liberia (+231)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1f7)} LR`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1f7),
    country: 'Liberia',
    countryAbbr: 'LR',
    countryAbbr3: 'LBR',
    value: '+231',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1fe)} Libya (+218)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1fe)} LY`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1fe),
    country: 'Libya',
    countryAbbr: 'LY',
    countryAbbr3: 'LBY',
    value: '+218',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1ee)} Liechtenstein (+423)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1ee)} LI`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1ee),
    country: 'Liechtenstein',
    countryAbbr: 'LI',
    countryAbbr3: 'LIE',
    value: '+423',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1f9)} Lithuania (+370)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1f9)} LT`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1f9),
    country: 'Lithuania',
    countryAbbr: 'LT',
    countryAbbr3: 'LTU',
    value: '+370',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1fa)} Luxembourg (+352)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1fa)} LU`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1fa),
    country: 'Luxembourg',
    countryAbbr: 'LU',
    countryAbbr3: 'LUX',
    value: '+352',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f4)} Macao (+853)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f4)} MO`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f4),
    country: 'Macao',
    countryAbbr: 'MO',
    countryAbbr3: 'MAC',
    value: '+853',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1ec)} Madagascar (+261)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1ec)} MG`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1ec),
    country: 'Madagascar',
    countryAbbr: 'MG',
    countryAbbr3: 'MDG',
    value: '+261',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1fc)} Malawi (+265)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1fc)} MW`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1fc),
    country: 'Malawi',
    countryAbbr: 'MW',
    countryAbbr3: 'MWI',
    value: '+265',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1fe)} Malaysia (+60)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1fe)} MY`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1fe),
    country: 'Malaysia',
    countryAbbr: 'MY',
    countryAbbr3: 'MYS',
    value: '+60',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1fb)} Maldives (+960)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1fb)} MV`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1fb),
    country: 'Maldives',
    countryAbbr: 'MV',
    countryAbbr3: 'MDV',
    value: '+960',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f1)} Mali (+223)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f1)} ML`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f1),
    country: 'Mali',
    countryAbbr: 'ML',
    countryAbbr3: 'MLI',
    value: '+223',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f9)} Malta (+365)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f9)} MT`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f9),
    country: 'Malta',
    countryAbbr: 'MT',
    countryAbbr3: 'MTL',
    value: '+365',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1ed)} Marshall Islands (+692)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1ed)} MH`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1ed),
    country: 'Marshall Islands',
    countryAbbr: 'MH',
    countryAbbr3: 'MHL',
    value: '+692',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f7)} Mauritania (+222)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f7)} MR`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f7),
    country: 'Mauritania',
    countryAbbr: 'MR',
    countryAbbr3: 'MRT',
    value: '+222',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1fa)} Mauritius (+230)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1fa)} MU`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1fa),
    country: 'Mauritius',
    countryAbbr: 'MU',
    countryAbbr3: 'MUS',
    value: '+230',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1fd)} Mexico (+52)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1fd)} MX`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1fd),
    country: 'Mexico',
    countryAbbr: 'MX',
    countryAbbr3: 'MEX',
    value: '+52',
  },
  {
    label: `${String.fromCodePoint(0x1f1eb, 0x1f1f2)} Micronesia (+691)`,
    shortLabel: `${String.fromCodePoint(0x1f1eb, 0x1f1f2)} FM`,
    flagCodePoint: String.fromCodePoint(0x1f1eb, 0x1f1f2),
    country: 'Micronesia',
    countryAbbr: 'FM',
    countryAbbr3: 'FSM',
    value: '+691',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1e9)} Moldova (+373)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1e9)} MD`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1e9),
    country: 'Moldova',
    countryAbbr: 'MD',
    countryAbbr3: 'MDA',
    value: '+373',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1e8)} Monaco (+377)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1e8)} MC`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1e8),
    country: 'Monaco',
    countryAbbr: 'MC',
    countryAbbr3: 'MCO',
    value: '+377',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f3)} Mongolia (+976)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f3)} MN`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f3),
    country: 'Mongolia',
    countryAbbr: 'MN',
    countryAbbr3: 'MNG',
    value: '+976',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1ea)} Montenegro (+382)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1ea)} ME`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1ea),
    country: 'Montenegro',
    countryAbbr: 'ME',
    countryAbbr3: 'MNE',
    value: '+382',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1e6)} Morocco (+212)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1e6)} MA`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1e6),
    country: 'Morocco',
    countryAbbr: 'MA',
    countryAbbr3: 'MAR',
    value: '+212',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1ff)} Mozambique (+258)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1ff)} MZ`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1ff),
    country: 'Mozambique',
    countryAbbr: 'MZ',
    countryAbbr3: 'MOZ',
    value: '+258',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f2)} Myanmar (+95)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f2)} MM`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f2),
    country: 'Myanmar',
    countryAbbr: 'MM',
    countryAbbr3: 'MMR',
    value: '+95',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1e6)} Namibia (+264)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1e6)} NA`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1e6),
    country: 'Namibia',
    countryAbbr: 'NA',
    countryAbbr3: 'NAM',
    value: '+264',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1f7)} Nauru (+674)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1f7)} NR`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1f7),
    country: 'Nauru',
    countryAbbr: 'NR',
    countryAbbr3: 'NRU',
    value: '+674',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1f5)} Nepal (+977)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1f5)} NP`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1f5),
    country: 'Nepal',
    countryAbbr: 'NP',
    countryAbbr3: 'NPL',
    value: '+977',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1f1)} Netherlands (+31)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1f1)} NL`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1f1),
    country: 'Netherlands',
    countryAbbr: 'NL',
    countryAbbr3: 'NLD',
    value: '+31',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1ff)} New Zealand (+64)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1ff)} NZ`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1ff),
    country: 'New Zealand',
    countryAbbr: 'NZ',
    countryAbbr3: 'NZL',
    value: '+64',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1ee)} Nicaragua (+505)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1ee)} NI`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1ee),
    country: 'Nicaragua',
    countryAbbr: 'NI',
    countryAbbr3: 'NIC',
    value: '+505',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1ea)} Niger (+227)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1ea)} NE`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1ea),
    country: 'Niger',
    countryAbbr: 'NE',
    countryAbbr3: 'NER',
    value: '+227',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1ec)} Nigeria (+234)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1ec)} NG`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1ec),
    country: 'Nigeria',
    countryAbbr: 'NG',
    countryAbbr3: 'NGA',
    value: '+234',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1f5)} North Korea (+850)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1f5)} KP`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1f5),
    country: 'North Korea',
    countryAbbr: 'KP',
    countryAbbr3: 'PRK',
    value: '+850',
  },
  {
    label: `${String.fromCodePoint(0x1f1f2, 0x1f1f0)} North Macedonia (+389)`,
    shortLabel: `${String.fromCodePoint(0x1f1f2, 0x1f1f0)} MK`,
    flagCodePoint: String.fromCodePoint(0x1f1f2, 0x1f1f0),
    country: 'North Macedonia',
    countryAbbr: 'MK',
    countryAbbr3: 'MKD',
    value: '+389',
  },
  {
    label: `${String.fromCodePoint(0x1f1f3, 0x1f1f4)} Norway (+47)`,
    shortLabel: `${String.fromCodePoint(0x1f1f3, 0x1f1f4)} NO`,
    flagCodePoint: String.fromCodePoint(0x1f1f3, 0x1f1f4),
    country: 'Norway',
    countryAbbr: 'NO',
    countryAbbr3: 'NOR',
    value: '+47',
  },
  {
    label: `${String.fromCodePoint(0x1f1f4, 0x1f1f2)} Oman (+968)`,
    shortLabel: `${String.fromCodePoint(0x1f1f4, 0x1f1f2)} OM`,
    flagCodePoint: String.fromCodePoint(0x1f1f4, 0x1f1f2),
    country: 'Oman',
    countryAbbr: 'OM',
    countryAbbr3: 'OMN',
    value: '+968',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1f0)} Pakistan (+92)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1f0)} PK`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1f0),
    country: 'Pakistan',
    countryAbbr: 'PK',
    countryAbbr3: 'PAK',
    value: '+92',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1fc)} Palau (+680)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1fc)} PW`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1fc),
    country: 'Palau',
    countryAbbr: 'PW',
    countryAbbr3: 'PLW',
    value: '+680',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1f8)} Palestine State (+970)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1f8)} PS`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1f8),
    country: 'Palestine State',
    countryAbbr: 'PS',
    countryAbbr3: 'PSE',
    value: '+970',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1e6)} Panama (+507)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1e6)} PA`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1e6),
    country: 'Panama',
    countryAbbr: 'PA',
    countryAbbr3: 'PAN',
    value: '+507',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1ec)} Papua New Guinea (+675)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1ec)} PG`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1ec),
    country: 'Papua New Guinea',
    countryAbbr: 'PG',
    countryAbbr3: 'PNG',
    value: '+675',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1fe)} Paraguay (+595)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1fe)} PY`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1fe),
    country: 'Paraguay',
    countryAbbr: 'PY',
    countryAbbr3: 'PRY',
    value: '+595',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1ea)} Peru (+51)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1ea)} PE`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1ea),
    country: 'Peru',
    countryAbbr: 'PE',
    countryAbbr3: 'PER',
    value: '+51',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1ed)} Philippines (+63)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1ed)} PH`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1ed),
    country: 'Philippines',
    countryAbbr: 'PH',
    countryAbbr3: 'PHL',
    value: '+63',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1f1)} Poland (+48)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1f1)} PL`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1f1),
    country: 'Poland',
    countryAbbr: 'PL',
    countryAbbr3: 'POL',
    value: '+48',
  },
  {
    label: `${String.fromCodePoint(0x1f1f5, 0x1f1f9)} Portugal (+351)`,
    shortLabel: `${String.fromCodePoint(0x1f1f5, 0x1f1f9)} PT`,
    flagCodePoint: String.fromCodePoint(0x1f1f5, 0x1f1f9),
    country: 'Portugal',
    countryAbbr: 'PT',
    countryAbbr3: 'PRT',
    value: '+351',
  },
  {
    label: `${String.fromCodePoint(0x1f1f6, 0x1f1e6)} Qatar (+974)`,
    shortLabel: `${String.fromCodePoint(0x1f1f6, 0x1f1e6)} QA`,
    flagCodePoint: String.fromCodePoint(0x1f1f6, 0x1f1e6),
    country: 'Qatar',
    countryAbbr: 'QA',
    countryAbbr3: 'QAT',
    value: '+974',
  },
  {
    label: `${String.fromCodePoint(0x1f1f7, 0x1f1f4)} Romania (+40)`,
    shortLabel: `${String.fromCodePoint(0x1f1f7, 0x1f1f4)} RO`,
    flagCodePoint: String.fromCodePoint(0x1f1f7, 0x1f1f4),
    country: 'Romania',
    countryAbbr: 'RO',
    countryAbbr3: 'ROU',
    value: '+40',
  },
  {
    label: `${String.fromCodePoint(0x1f1f7, 0x1f1fa)} Russia (+7)`,
    shortLabel: `${String.fromCodePoint(0x1f1f7, 0x1f1fa)} RU`,
    flagCodePoint: String.fromCodePoint(0x1f1f7, 0x1f1fa),
    country: 'Russia',
    countryAbbr: 'RU',
    countryAbbr3: 'RUS',
    value: '+7',
  },
  {
    label: `${String.fromCodePoint(0x1f1f7, 0x1f1fc)} Rwanda (+250)`,
    shortLabel: `${String.fromCodePoint(0x1f1f7, 0x1f1fc)} RW`,
    flagCodePoint: String.fromCodePoint(0x1f1f7, 0x1f1fc),
    country: 'Rwanda',
    countryAbbr: 'RW',
    countryAbbr3: 'RWA',
    value: '+250',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1f3)} Saint Kitts and Nevis (+1869)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1f3)} KN`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1f3),
    country: 'Saint Kitts and Nevis',
    countryAbbr: 'KN',
    countryAbbr3: 'KNA',
    value: '+1869',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1e8)} Saint Lucia (+1758)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1e8)} LC`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1e8),
    country: 'Saint Lucia',
    countryAbbr: 'LC',
    countryAbbr3: 'LCA',
    value: '+1758',
  },
  {
    label: `${String.fromCodePoint(0x1f1fb, 0x1f1e8)} Saint Vincent and the Grenadines (+1784)`,
    shortLabel: `${String.fromCodePoint(0x1f1fb, 0x1f1e8)} VC`,
    flagCodePoint: String.fromCodePoint(0x1f1fb, 0x1f1e8),
    country: 'Saint Vincent and the Grenadines',
    countryAbbr: 'VC',
    countryAbbr3: 'VCT',
    value: '+1784',
  },
  {
    label: `${String.fromCodePoint(0x1f1fc, 0x1f1f8)} Samoa (+685)`,
    shortLabel: `${String.fromCodePoint(0x1f1fc, 0x1f1f8)} WS`,
    flagCodePoint: String.fromCodePoint(0x1f1fc, 0x1f1f8),
    country: 'Samoa',
    countryAbbr: 'WS',
    countryAbbr3: 'WSM',
    value: '+685',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f2)} San Marino (+39)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f2)} SM`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f2),
    country: 'San Marino',
    countryAbbr: 'SM',
    countryAbbr3: 'SMR',
    value: '+39',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f9)} Sao Tome and Principe (+239)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f9)} ST`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f9),
    country: 'Sao Tome and Principe',
    countryAbbr: 'ST',
    countryAbbr3: 'STP',
    value: '+239',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1e6)} Saudi Arabia (+966)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1e6)} SA`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1e6),
    country: 'Saudi Arabia',
    countryAbbr: 'SA',
    countryAbbr3: 'SAU',
    value: '+966',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f3)} Senegal (+221)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f3)} SN`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f3),
    country: 'Senegal',
    countryAbbr: 'SN',
    countryAbbr3: 'SEN',
    value: '+221',
  },
  {
    label: `${String.fromCodePoint(0x1f1f7, 0x1f1f8)} Serbia (+381)`,
    shortLabel: `${String.fromCodePoint(0x1f1f7, 0x1f1f8)} RS`,
    flagCodePoint: String.fromCodePoint(0x1f1f7, 0x1f1f8),
    country: 'Serbia',
    countryAbbr: 'RS',
    countryAbbr3: 'SRB',
    value: '+381',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1e8)} Seychelles (+248)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1e8)} SC`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1e8),
    country: 'Seychelles',
    countryAbbr: 'SC',
    countryAbbr3: 'SYC',
    value: '+248',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f1)} Sierra Leone (+232)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f1)} SL`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f1),
    country: 'Sierra Leone',
    countryAbbr: 'SL',
    countryAbbr3: 'SLE',
    value: '+232',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1ec)} Singapore (+65)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1ec)} SG`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1ec),
    country: 'Singapore',
    countryAbbr: 'SG',
    countryAbbr3: 'SGP',
    value: '+65',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f0)} Slovakia (+421)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f0)} SK`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f0),
    country: 'Slovakia',
    countryAbbr: 'SK',
    countryAbbr3: 'SVK',
    value: '+421',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1ee)} Slovenia (+386)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1ee)} SI`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1ee),
    country: 'Slovenia',
    countryAbbr: 'SI',
    countryAbbr3: 'SVN',
    value: '+386',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1e7)} Solomon Islands (+677)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1e7)} SB`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1e7),
    country: 'Solomon Islands',
    countryAbbr: 'SB',
    countryAbbr3: 'SLB',
    value: '+677',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f4)} Somalia (+252)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f4)} SO`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f4),
    country: 'Somalia',
    countryAbbr: 'SO',
    countryAbbr3: 'SOM',
    value: '+252',
  },
  {
    label: `${String.fromCodePoint(0x1f1ff, 0x1f1e6)} South Africa (+27)`,
    shortLabel: `${String.fromCodePoint(0x1f1ff, 0x1f1e6)} ZA`,
    flagCodePoint: String.fromCodePoint(0x1f1ff, 0x1f1e6),
    country: 'South Africa',
    countryAbbr: 'ZA',
    countryAbbr3: 'ZAF',
    value: '+27',
  },
  {
    label: `${String.fromCodePoint(0x1f1f0, 0x1f1f7)} South Korea (+82)`,
    shortLabel: `${String.fromCodePoint(0x1f1f0, 0x1f1f7)} KR`,
    flagCodePoint: String.fromCodePoint(0x1f1f0, 0x1f1f7),
    country: 'South Korea',
    countryAbbr: 'KR',
    countryAbbr3: 'KOR',
    value: '+82',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f8)} South Sudan (+211)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f8)} SS`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f8),
    country: 'South Sudan',
    countryAbbr: 'SS',
    countryAbbr3: 'SSD',
    value: '+211',
  },
  {
    label: `${String.fromCodePoint(0x1f1ea, 0x1f1f8)} Spain (+34)`,
    shortLabel: `${String.fromCodePoint(0x1f1ea, 0x1f1f8)} ES`,
    flagCodePoint: String.fromCodePoint(0x1f1ea, 0x1f1f8),
    country: 'Spain',
    countryAbbr: 'ES',
    countryAbbr3: 'ESP',
    value: '+34',
  },
  {
    label: `${String.fromCodePoint(0x1f1f1, 0x1f1f0)} Sri Lanka (+94)`,
    shortLabel: `${String.fromCodePoint(0x1f1f1, 0x1f1f0)} LK`,
    flagCodePoint: String.fromCodePoint(0x1f1f1, 0x1f1f0),
    country: 'Sri Lanka',
    countryAbbr: 'LK',
    countryAbbr3: 'LKA',
    value: '+94',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1e9)} Sudan (+249)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1e9)} SD`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1e9),
    country: 'Sudan',
    countryAbbr: 'SD',
    countryAbbr3: 'SDN',
    value: '+249',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1f7)} Suriname (+597)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1f7)} SR`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1f7),
    country: 'Suriname',
    countryAbbr: 'SR',
    countryAbbr3: 'SUR',
    value: '+597',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1ea)} Sweden (+46)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1ea)} SE`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1ea),
    country: 'Sweden',
    countryAbbr: 'SE',
    countryAbbr3: 'SWE',
    value: '+46',
  },
  {
    label: `${String.fromCodePoint(0x1f1e8, 0x1f1ed)} Switzerland (+41)`,
    shortLabel: `${String.fromCodePoint(0x1f1e8, 0x1f1ed)} CH`,
    flagCodePoint: String.fromCodePoint(0x1f1e8, 0x1f1ed),
    country: 'Switzerland',
    countryAbbr: 'CH',
    countryAbbr3: 'CHE',
    value: '+41',
  },
  {
    label: `${String.fromCodePoint(0x1f1f8, 0x1f1fe)} Syria (+963)`,
    shortLabel: `${String.fromCodePoint(0x1f1f8, 0x1f1fe)} SY`,
    flagCodePoint: String.fromCodePoint(0x1f1f8, 0x1f1fe),
    country: 'Syria',
    countryAbbr: 'SY',
    countryAbbr3: 'SYR',
    value: '+963',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1fc)} Taiwan (+886)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1fc)} TW`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1fc),
    country: 'Taiwan',
    countryAbbr: 'TW',
    countryAbbr3: 'TWN',
    value: '+886',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1ef)} Tajikistan (+992)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1ef)} TJ`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1ef),
    country: 'Tajikistan',
    countryAbbr: 'TJ',
    countryAbbr3: 'TJK',
    value: '+992',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1ff)} Tanzania (+255)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1ff)} TZ`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1ff),
    country: 'Tanzania',
    countryAbbr: 'TZ',
    countryAbbr3: 'TZA',
    value: '+255',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1ed)} Thailand (+66)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1ed)} TH`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1ed),
    country: 'Thailand',
    countryAbbr: 'TH',
    countryAbbr3: 'THA',
    value: '+66',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1f1)} Timor-Leste (+670)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1f1)} TL`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1f1),
    country: 'Timor-Leste',
    countryAbbr: 'TL',
    countryAbbr3: 'TLS',
    value: '+670',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1ec)} Togo (+228)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1ec)} TG`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1ec),
    country: 'Togo',
    countryAbbr: 'TG',
    countryAbbr3: 'TGO',
    value: '+228',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1f4)} Tonga (+676)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1f4)} TO`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1f4),
    country: 'Tonga',
    countryAbbr: 'TO',
    countryAbbr3: 'TON',
    value: '+676',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1f9)} Trinidad and Tobago (+868)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1f9)} TT`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1f9),
    country: 'Trinidad and Tobago',
    countryAbbr: 'TT',
    countryAbbr3: 'TTO',
    value: '+868',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1f3)} Tunisia (+216)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1f3)} TN`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1f3),
    country: 'Tunisia',
    countryAbbr: 'TN',
    countryAbbr3: 'TUN',
    value: '+216',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1f7)} Turkey (+90)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1f7)} TR`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1f7),
    country: 'Turkey',
    countryAbbr: 'TR',
    countryAbbr3: 'TUR',
    value: '+90',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1f2)} Turkmenistan (+993)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1f2)} TM`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1f2),
    country: 'Turkmenistan',
    countryAbbr: 'TM',
    countryAbbr3: 'TKM',
    value: '+993',
  },
  {
    label: `${String.fromCodePoint(0x1f1f9, 0x1f1fb)} Tuvalu (+688)`,
    shortLabel: `${String.fromCodePoint(0x1f1f9, 0x1f1fb)} TV`,
    flagCodePoint: String.fromCodePoint(0x1f1f9, 0x1f1fb),
    country: 'Tuvalu',
    countryAbbr: 'TV',
    countryAbbr3: 'TUV',
    value: '+688',
  },
  {
    label: `${String.fromCodePoint(0x1f1fa, 0x1f1ec)} Uganda (+256)`,
    shortLabel: `${String.fromCodePoint(0x1f1fa, 0x1f1ec)} UG`,
    flagCodePoint: String.fromCodePoint(0x1f1fa, 0x1f1ec),
    country: 'Uganda',
    countryAbbr: 'UG',
    countryAbbr3: 'UGA',
    value: '+256',
  },
  {
    label: `${String.fromCodePoint(0x1f1fa, 0x1f1e6)} Ukraine (+380)`,
    shortLabel: `${String.fromCodePoint(0x1f1fa, 0x1f1e6)} UA`,
    flagCodePoint: String.fromCodePoint(0x1f1fa, 0x1f1e6),
    country: 'Ukraine',
    countryAbbr: 'UA',
    countryAbbr3: 'UKR',
    value: '+380',
  },
  {
    label: `${String.fromCodePoint(0x1f1e6, 0x1f1ea)} United Arab Emirates (+971)`,
    shortLabel: `${String.fromCodePoint(0x1f1e6, 0x1f1ea)} AE`,
    flagCodePoint: String.fromCodePoint(0x1f1e6, 0x1f1ea),
    country: 'United Arab Emirates',
    countryAbbr: 'AE',
    countryAbbr3: 'ARE',
    value: '+971',
  },
  {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1e7)} United Kingdom (+44)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1e7)} UK`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1e7),
    country: 'United Kingdom',
    countryAbbr: 'UK',
    countryAbbr3: 'GBR',
    value: '+44',
  },
  {
    label: `${String.fromCodePoint(0x1f1fa, 0x1f1f8)} United States (+1)`,
    shortLabel: `${String.fromCodePoint(0x1f1fa, 0x1f1f8)} US`,
    flagCodePoint: String.fromCodePoint(0x1f1fa, 0x1f1f8),
    country: 'United States',
    countryAbbr: 'US',
    countryAbbr3: 'USA',
    value: '+1',
  },
  {
    label: `${String.fromCodePoint(0x1f1fa, 0x1f1fe)} Uruguay (+598)`,
    shortLabel: `${String.fromCodePoint(0x1f1fa, 0x1f1fe)} UY`,
    flagCodePoint: String.fromCodePoint(0x1f1fa, 0x1f1fe),
    country: 'Uruguay',
    countryAbbr: 'UY',
    countryAbbr3: 'URY',
    value: '+598',
  },
  {
    label: `${String.fromCodePoint(0x1f1fa, 0x1f1ff)} Uzbekistan (+998)`,
    shortLabel: `${String.fromCodePoint(0x1f1fa, 0x1f1ff)} UZ`,
    flagCodePoint: String.fromCodePoint(0x1f1fa, 0x1f1ff),
    country: 'Uzbekistan',
    countryAbbr: 'UZ',
    countryAbbr3: 'UZB',
    value: '+998',
  },
  {
    label: `${String.fromCodePoint(0x1f1fb, 0x1f1fa)} Vanuatu (+678)`,
    shortLabel: `${String.fromCodePoint(0x1f1fb, 0x1f1fa)} UZ`,
    flagCodePoint: String.fromCodePoint(0x1f1fb, 0x1f1fa),
    country: 'Vanuatu',
    countryAbbr: 'VU',
    countryAbbr3: 'VUT',
    value: '+678',
  },
  {
    label: `${String.fromCodePoint(0x1f1fb, 0x1f1ea)} Venezuela (+58)`,
    shortLabel: `${String.fromCodePoint(0x1f1fb, 0x1f1ea)} VE`,
    flagCodePoint: String.fromCodePoint(0x1f1fb, 0x1f1ea),
    country: 'Venezuela',
    countryAbbr: 'VE',
    countryAbbr3: 'VEN',
    value: '+58',
  },
  {
    label: `${String.fromCodePoint(0x1f1fb, 0x1f1f3)} Vietnam (+84)`,
    shortLabel: `${String.fromCodePoint(0x1f1fb, 0x1f1f3)} VN`,
    flagCodePoint: String.fromCodePoint(0x1f1fb, 0x1f1f3),
    country: 'Vietnam',
    countryAbbr: 'VN',
    countryAbbr3: 'VNM',
    value: '+84',
  },
  {
    label: `${String.fromCodePoint(0x1f1fe, 0x1f1ea)} Yemen (+967)`,
    shortLabel: `${String.fromCodePoint(0x1f1fe, 0x1f1ea)} YE`,
    flagCodePoint: String.fromCodePoint(0x1f1fe, 0x1f1ea),
    country: 'Yemen',
    countryAbbr: 'YE',
    countryAbbr3: 'YEM',
    value: '+967',
  },
  {
    label: `${String.fromCodePoint(0x1f1ff, 0x1f1f2)} Zambia (+260)`,
    shortLabel: `${String.fromCodePoint(0x1f1ff, 0x1f1f2)} ZM`,
    flagCodePoint: String.fromCodePoint(0x1f1ff, 0x1f1f2),
    country: 'Zambia',
    countryAbbr: 'ZM',
    countryAbbr3: 'ZMB',
    value: '+260',
  },
  {
    label: `${String.fromCodePoint(0x1f1ff, 0x1f1fc)} Zimbabwe (+263)`,
    shortLabel: `${String.fromCodePoint(0x1f1ff, 0x1f1fc)} ZW`,
    flagCodePoint: String.fromCodePoint(0x1f1ff, 0x1f1fc),
    country: 'Zimbabwe',
    countryAbbr: 'ZW',
    countryAbbr3: 'ZWE',
    value: '+263',
  },
];
