import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type DeleteUnconfirmedUserConfig = {
  username: string;
};

type DeleteUnconfirmedUser = (config: DeleteUnconfirmedUserConfig) => Promise<UserType>;

const deleteUnconfirmedUser: DeleteUnconfirmedUser = async ({ username }: DeleteUnconfirmedUserConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/users/${username}`;
    const deleteUnconfirmedUserResp: AxiosResponse<UserType> = await axios.request({
      method: 'DELETE',
      url: reqPath,
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = deleteUnconfirmedUserResp.status;
    if (status !== HttpStatus.OK) throw new Error('incorrect_status_code');
    return deleteUnconfirmedUserResp.data;
  } catch (err) {
    throw err;
  }
};

export default deleteUnconfirmedUser;
