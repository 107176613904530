import axios from 'axios';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const emailUsed = async (email: string) => {
  if (!email) throw new Error('No email given');
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/users/${email}/email`;
    const emailUsedObject = await axios.request({
      method: 'GET',
      url: reqPath,
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    return emailUsedObject.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export default emailUsed;
