import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type CheckConfirmedStartConfig = {
  username: string;
};

type CheckConfirmedStart = (config: CheckConfirmedStartConfig) => void;

const checkConfirmedStatus: CheckConfirmedStart = async ({ username }: CheckConfirmedStartConfig) => {
  const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/users/${username}/check-confirmed-start`;
  axios.request({
    method: 'POST',
    url: reqPath,
    headers: {
      'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
    },
  });
};

export default checkConfirmedStatus;
