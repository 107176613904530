import jwtDecode from 'jwt-decode';

interface Claims {
  [key: string]: string;
}

const updateLocalTokens = (AuthenticationResult: any) => {
  let accessToken = AuthenticationResult.AccessToken;
  let idToken = AuthenticationResult.IdToken;
  let claims: Claims = jwtDecode(accessToken || '');
  accessToken && localStorage.setItem('access_token', accessToken);
  accessToken && localStorage.setItem('token', accessToken);
  idToken && localStorage.setItem('id_token', idToken);
  claims && localStorage.setItem('token_exp', claims.exp);
  return { accessToken, idToken, claims };
};

export default updateLocalTokens;
