import Router from 'next/router';

const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('token_exp');
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
  return Router.push('/login');
};

export default logoutUser;
