import { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosErrorHandler } from '../../utils';
import { axiosWithAuth } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type VerifyAttributeConfig = {
  code: string;
  attributeName: string;
};

type VerifyAttribute = (config: VerifyAttributeConfig) => Promise<string>;

const verifyAttribute: VerifyAttribute = async ({ code, attributeName }: VerifyAttributeConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/verify-attribute`;
    const verifyAttributeObject: AxiosResponse<string> = await axiosWithAuth.request({
      method: 'post',
      url: reqPath,
      data: {
        code,
        attributeName,
      },
    });
    const status = verifyAttributeObject.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return verifyAttributeObject.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default verifyAttribute;
