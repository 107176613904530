import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';

import { Attribute } from '../../types';
import { axiosErrorHandler } from '../../utils';
import { axiosWithAuth } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type UpdateUserAttrsConfig = {
  userAttributes: Attribute[];
};

type UpdateUserAttrs = (config: UpdateUserAttrsConfig) => Promise<UserType>;

const updaterUserAttrs: UpdateUserAttrs = async (config: UpdateUserAttrsConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/me`;
    const updaterUserAttrsResp: AxiosResponse<UserType> = await axiosWithAuth.request({
      method: 'put',
      url: reqPath,
      data: {
        userAttributes: config.userAttributes || [],
      },
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = updaterUserAttrsResp.status;
    if (status !== HttpStatus.OK) throw new Error('incorrect_status_code');
    return updaterUserAttrsResp.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default updaterUserAttrs;
