import { SignUpResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';

import { Attribute } from '../../types';
import { axiosErrorHandler } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type RegisterUserConfig = {
  username: string;
  password: string;
  userAttributes: Attribute[];
};

type RegisterUser = (config: RegisterUserConfig) => Promise<SignUpResponse>;

const registerUser: RegisterUser = async ({ username, password, userAttributes }: RegisterUserConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/signup`;
    const registerUserObject: AxiosResponse<SignUpResponse> = await axios.request({
      method: 'POST',
      url: reqPath,
      data: {
        username,
        password,
        userAttributes,
      },
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = registerUserObject.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return registerUserObject.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default registerUser;
