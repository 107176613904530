const handleEnter = (event: any) => {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    for (let i = index + 1; i < form.elements.length; i++) {
      if (form.elements[i].tagName === 'INPUT') {
        console.log(form.elements[i]);
        form.elements[i].focus();
        break;
      } else if (form.elements[i].tagName === 'BUTTON' && form.elements[i].type === 'submit') {
        form.elements[index].blur();
        form.elements[i].click();
      }
    }
    event.preventDefault();
  }
};

export default handleEnter;
