import queryString from 'query-string';

interface GetRedirectUrlConfig {
  originalRedirectPage: string;
}

const getRedirectUrl = ({ originalRedirectPage }: GetRedirectUrlConfig) => {
  const currentQueryString = queryString.parseUrl(originalRedirectPage);
  const newQuery = {
    ...currentQueryString.query,
  };
  const finalUrl = queryString.stringifyUrl({
    url: currentQueryString.url,
    query: newQuery,
  });
  return finalUrl;
};

export default getRedirectUrl;
