import axios from 'axios';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const usernameAvailable = async (username: string) => {
  if (!username) throw new Error('No username given');
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/users/${username}/available`;
    const usernameAvailableObject = await axios.request({
      method: 'GET',
      url: reqPath,
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    return usernameAvailableObject.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export default usernameAvailable;
