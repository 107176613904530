import { ListUsersResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosWithAuth } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type ListUsersConfig = {
  startString: string;
  cursor?: string;
  limit?: number;
};

type ListUsers = (config: ListUsersConfig) => Promise<ListUsersResponse>;

const listUsers: ListUsers = async ({ startString, cursor, limit }: ListUsersConfig) => {
  const qs = queryString.stringify(
    { startString, cursor, limit },
    {
      skipNull: true,
      encode: true,
    },
  );
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/users?${qs}`;
    const listUsersResp: AxiosResponse<ListUsersResponse> = await axiosWithAuth.request({
      method: 'GET',
      url: reqPath,
    });
    const status = listUsersResp.status;
    if (status !== HttpStatus.OK) throw new Error('incorrect_status_code');
    return listUsersResp.data;
  } catch (err) {
    throw err;
  }
};

export default listUsers;
