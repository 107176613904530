const paragraphsToShow = (paragraphs: string[], numberOfCharacters: number) => {
  let output: string[] = [];
  let characterTotal: number = 0;

  for (let i = 0; i < paragraphs.length; i++) {
    const currentTotal = characterTotal;

    characterTotal += paragraphs[i].length;

    if (characterTotal < numberOfCharacters) {
      output = [...output, paragraphs[i]];
    } else {
      const charactersToShow = numberOfCharacters - currentTotal;
      output = [...output, paragraphs[i].substring(0, charactersToShow)];
      break;
    }
  }

  return output;
};

export default paragraphsToShow;
