import { AttributeType, UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import {} from '../types';

type GetAttributeFromUserType = {
  userType: UserType;
  attrName: string;
};

const getAttributeFromUserType = ({ userType, attrName }: GetAttributeFromUserType) => {
  if (!userType.Attributes) return '';
  const filteredArray = userType.Attributes.filter((attr: AttributeType) => attr.Name === attrName);
  if (filteredArray.length === 0) return '';
  const attrValue = filteredArray[0].Value;
  if (!attrValue) return '';
  return attrValue;
};

export default getAttributeFromUserType;
