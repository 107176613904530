import jwtDecode from 'jwt-decode';
import Router from 'next/router';
import { decodeVerifyJWT } from '.';
import { AccessLevel } from '../types';

type PageAuthConfig = {
  location: string;
  accessLevel: AccessLevel;
};

type Claims = {
  [key: string]: any;
};

type PageAuthResult = {
  claims: Claims;
  idClaims: Claims;
};

type PageAuth = (config: PageAuthConfig) => Promise<PageAuthResult>;

const pageAuth: PageAuth = async ({ location, accessLevel }: PageAuthConfig) => {
  const token = localStorage.getItem('access_token');
  const id_token = localStorage.getItem('id_token');
  const loginLocation = `/login?redirectPage=${encodeURIComponent(location)}`.replace('//', '/');
  if (!token) {
    await Router.push(loginLocation);
    const claims: Claims = {};
    const idClaims: Claims = {};
    return { claims, idClaims };
  }

  if (token) {
    try {
      const claims: Claims = await decodeVerifyJWT();
      const idClaims: Claims = id_token ? jwtDecode(id_token) : {};
      const emailVerified = idClaims.email_verified || false;
      if (emailVerified !== true) {
        await Router.push(`${loginLocation}&panel=normalLoginPanel&verify=email`);
        const claims: Claims = {};
        const idClaims: Claims = {};
        return { claims, idClaims };
      }
      const birthdate = idClaims.birthdate;
      if (birthdate === undefined || birthdate === '') {
        await Router.push(`${loginLocation}&panel=registerPanel&required=birthdate`);
        const claims: Claims = {};
        const idClaims: Claims = {};
        return { claims, idClaims };
      }
      const location = idClaims['custom:city'];
      if (location === undefined || location === '') {
        await Router.push(`${loginLocation}&panel=registerPanel&required=location`);
        const claims: Claims = {};
        const idClaims: Claims = {};
        return { claims, idClaims };
      }
      const termsAccepted = idClaims['custom:has_accepted_terms'] || false;
      if (!termsAccepted || termsAccepted === '') {
        await Router.push(loginLocation);
        const claims: Claims = {};
        const idClaims: Claims = {};
        return { claims, idClaims };
      }
      switch (accessLevel) {
        case AccessLevel.admin: {
          const groups = claims['cognito:groups'];
          if (!groups) {
            await Router.push('/');
            const claims: Claims = {};
            const idClaims: Claims = {};
            return { claims, idClaims };
          }
          if (groups.indexOf('role:admin') === -1) {
            await Router.push('/');
            const claims: Claims = {};
            const idClaims: Claims = {};
            return { claims, idClaims };
          }
          if (groups.indexOf('role:admin') > -1) {
            return { claims, idClaims };
          }
          throw new Error('unknown_admin_level_error');
        }
        case AccessLevel.business: {
          const groups = claims['cognito:groups'];
          if (!groups) {
            await Router.push('/');
            const claims: Claims = {};
            const idClaims: Claims = {};
            return { claims, idClaims };
          }
          if (groups.indexOf('role:business') === -1 && groups.indexOf('role:admin') === -1) {
            await Router.push('/');
            const claims: Claims = {};
            const idClaims: Claims = {};
            return { claims, idClaims };
          }
          if (groups.indexOf('role:business') > -1 || groups.indexOf('role:admin') > -1) {
            return { claims, idClaims };
          }
          throw new Error('unknown_business_level_error');
        }
        case AccessLevel.customer: {
          return { claims, idClaims };
        }
        default:
          throw new Error('no_access_level_set');
      }
    } catch {
      await Router.push(loginLocation);
      const claims: Claims = {};
      const idClaims: Claims = {};
      return { claims, idClaims };
    }
  }
  const claims: Claims = {};
  const idClaims: Claims = {};
  return { claims, idClaims };
};

export default pageAuth;
