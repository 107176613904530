import { useMemo, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import getConfig from 'next/config';
import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';

type Feature = {
  place_name_en: string;
};

interface PredictResp {
  features: Array<Feature>;
}

const { publicRuntimeConfig } = getConfig();

const useMapboxPlacePredictions = (inputValue: string, options: any) => {
  const [predictions, setPredictions] = useState<Array<string>>([]);

  const getPlacePredictions = async (request: any, callback: any) => {
    const response: AxiosResponse<PredictResp> = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${request.input}.json?access_token=${publicRuntimeConfig.MAPBOX_PLACES_API_TOKEN}&types=place&language=en`,
    );
    const status = response.status;
    if (status !== HttpStatus.OK) callback(null);
    callback(response.data.features);
  };

  const fetch = useMemo(
    () =>
      throttle((request: any, callback: any) => {
        getPlacePredictions(request, callback);
      }, 500),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setPredictions([]);
      return () => (active = false);
    }

    fetch({ input: inputValue, ...options }, (predictions: Array<Feature>) => {
      if (active) {
        setPredictions(predictions ? predictions.map((prediction: Feature) => prediction.place_name_en) : []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return predictions;
};

export default useMapboxPlacePredictions;
