import { InitiateAuthResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';

import { axiosErrorHandler } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type AuthenticateUserConfig = {
  username: string;
  password: string;
};

type AuthenticateUser = (config: AuthenticateUserConfig) => Promise<InitiateAuthResponse>;

const authenticateUser: AuthenticateUser = async ({ username, password }: AuthenticateUserConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/signin`;
    const authenticateUserObject: AxiosResponse<InitiateAuthResponse> = await axios.request({
      method: 'post',
      url: reqPath,
      data: {
        username,
        password,
      },
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = authenticateUserObject.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return authenticateUserObject.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default authenticateUser;
