import getConfig from 'next/config';
import { axiosErrorHandler, axiosWithAuth } from '../../utils';

const { publicRuntimeConfig } = getConfig();

const assetProvenance: any = async (assetId: string) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/cache/provenance?asset-id=${assetId}`;
    const assetsResponse: any = await axiosWithAuth.get(reqPath, {});
    return assetsResponse;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default assetProvenance;
