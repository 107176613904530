import * as React from 'react';

function useFetch(url: string, opts?: any) {
  const [response, setResponse] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [hasError, setHasError] = React.useState(false);
  React.useEffect(() => {
    async function fetchCall() {
      setLoading(true);
      try {
        const response = await (await fetch(url, opts)).json();
        setResponse(response);
      } catch (error) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchCall();
  }, [url]);
  return { response, loading, hasError };
}

export default useFetch;
