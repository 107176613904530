import { countryCodes } from '../constants';
import { CountryCodeNew } from '../types';

const getCountryCode: (country: string) => CountryCodeNew | null = (country: string) => {
  // // by default UK
  // let result = countryCodes[188];
  if (!country) {
    return null;
  }
  let result = null;
  countryCodes.forEach((countryObj: CountryCodeNew) => {
    if (
      country.toUpperCase() == countryObj.country.toUpperCase() ||
      country.toUpperCase() == countryObj.countryAbbr.toUpperCase() ||
      country.toUpperCase() == countryObj.countryAbbr3.toUpperCase()
    ) {
      result = countryObj;
    }
  });
  return result;
};

export default getCountryCode;
