import axios from 'axios';

import getConfig from 'next/config';
import HttpStatus from 'http-status-codes';

const { publicRuntimeConfig } = getConfig();

const refreshTokens = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/refresh-auth/${refreshToken}`;
  const response = await axios.request({
    method: 'GET',
    url: reqPath,
    headers: {
      'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
    },
  });
  const status = response.status;
  if (status !== HttpStatus.OK) throw new Error('incorrect_status_code');
  return response.data;
};

export default refreshTokens;
