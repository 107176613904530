import { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosErrorHandler } from '../../utils';
import { axiosWithAuth } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type GetAttributeVerificationConfig = {
  attributeName: string;
};

type GetAttributeVerification = ({ attributeName }: GetAttributeVerificationConfig) => Promise<any>;

const getAttributeVerification: GetAttributeVerification = async ({
  attributeName,
}: GetAttributeVerificationConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/get-attribute-verification-code`;
    const getAttributeVerificationResp: AxiosResponse<any> = await axiosWithAuth.request({
      method: 'post',
      url: reqPath,
      data: { attributeName },
    });
    const status = getAttributeVerificationResp.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return getAttributeVerificationResp.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default getAttributeVerification;
