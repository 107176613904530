import jwtDecode from 'jwt-decode';
import { decodeVerifyJWT } from '.';

type Claims = {
  [key: string]: any;
};

type CheckIfLoggedIn = () => Promise<boolean>;

const checkIfLoggedIn: CheckIfLoggedIn = async () => {
  const token = localStorage.getItem('access_token');
  const id_token = localStorage.getItem('id_token');
  if (!token) {
    return false;
  }
  try {
    await decodeVerifyJWT();
    const idClaims: Claims = id_token ? jwtDecode(id_token) : {};
    const location = idClaims['custom:city'];
    if (location === undefined || location === '') {
      return false;
    }

    const emailVerified = idClaims['email_verified'] || false;
    if (!emailVerified) {
      return false;
    }

    const termsAccepted = idClaims['custom:has_accepted_terms'] || false;
    if (!termsAccepted) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export default checkIfLoggedIn;
