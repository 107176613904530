const axiosErrorHandler = (err: any) => {
  if (!err.response) return err;
  if (!err.response.data) return err;
  if (!err.response.data.message) return err;
  const { message } = err.response.data;
  if (typeof message === 'string') return new Error(message);
  if (typeof message !== 'object') return err;
  if (message.length < 1) return err;
  const firstMessage = message[0];
  const { constraints } = firstMessage;
  if (constraints) {
    const constraintArray = Object.keys(constraints).map(key => constraints[key]);
    return new Error(constraintArray[0]);
  }
  return new Error(firstMessage);
};

export default axiosErrorHandler;
