import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosWithAuth } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type GetUserConfig = {
  sub: string;
};

type GetUser = (config: GetUserConfig) => Promise<UserType>;

const getUser: GetUser = async ({ sub }: GetUserConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/users/${sub}`;
    const getUserResp: AxiosResponse<UserType> = await axiosWithAuth.request({
      method: 'GET',
      url: reqPath,
    });
    const status = getUserResp.status;
    if (status !== HttpStatus.OK) throw new Error('incorrect_status_code');
    return getUserResp.data;
  } catch (err) {
    throw err;
  }
};

export default getUser;
