import { ConfirmForgotPasswordResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import axios, { AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import { axiosErrorHandler } from '../../utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type ConfirmForgotPasswordConfig = {
  username: string;
  password: string;
  code: string;
};

type ConfirmForgotPassword = (config: ConfirmForgotPasswordConfig) => Promise<ConfirmForgotPasswordResponse>;

const confirmForgotPassword: ConfirmForgotPassword = async (config: ConfirmForgotPasswordConfig) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/user/auth/confirm-forgot-password`;
    const confirmForgotPasswordResp: AxiosResponse<ConfirmForgotPasswordResponse> = await axios.request({
      method: 'post',
      url: reqPath,
      data: config,
      headers: {
        'X-Evl-Client-Id': publicRuntimeConfig.EVL_CLIENT_ID,
      },
    });
    const status = confirmForgotPasswordResp.status;
    if (status !== HttpStatus.CREATED) throw new Error('incorrect_status_code');
    return confirmForgotPasswordResp.data;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default confirmForgotPassword;
